export default {
  REGISTER_SUCCESS: 'Register successfully completed.',
  ERROR_OCCURED_FROM_SERVER: 'Error occured from server',
  ERROR_OCCURED_FROM_CLIENT: 'Error occured from client request.',
  SESSION_TERMINATED: 'Session Terminated.',
  DELETE_SUCCESSED: 'Delete completed.',
  UPDATED_SUCCESSED: 'Update completed.',
  UPLOAD_SUCCESSED: 'Upload completed.',
  STOP_STREAM_SUCCESS: 'Stream stopped successfully',

  LOGIN_PASSED: 'Login Success',
  FORBIDEN: 'You do not have permission for this action',
  INVALID_LOGIN_INFO: 'Invalid email and password',
  INVALID_FACES: 'Please take your all faces',
  CLIENT_NOT_SELECTED: 'Please select at least one client.',
  CLIENT_NOT_ALLOWED: 'Please wait until admin allows you',
  SEND_COLORING_PDF: 'Sending coloring pdf completed successfuly',
  PROXY_NOT_EXISTS: 'Proxy server is not working now',
  DUPLICATED_CAMERA: 'Same camera is already exists',
  SEND_VIDEO_SUCCESS: 'The Video has been sent successfully',
  ALERT_EMAIL_VERIFIED_SUCCESS: 'Your email has been verified successfully',
  ALERT_RESEND_EMAIL_SUCCESS: 'We sent you verification email again',
  ERROR_PAYMENT: 'Your payment has been failed',
  ALERT_PAYMENT_SUCCESS: 'Your payment has been completed',
  TOURPLACE_SELECT_ERROR: 'Please select tour place',
  ALERT_RSTART_CAMERA_SUCCESS: 'Restarted Camera Successfully',
  PUSH_NOTIFICATION_SUCCESS: "Push notification Success!",
  PAID_SUCCESS: 'Your payment was successful',
  PAID_PENDING: 'Your payment will be processed soon',
  SELECT_PAID_VERSION: 'Please select recording version',
  CANNOT_RECORD: "Sorry, Can't record video",
  CONNECTING_CAMERA_ERROR: "Sorry, Can't connect the camera",
  STOP_CAMERA_ERROR: "Stop camera failed",
  SELECT_CLIENT: "Please select at least one client.",
  INPUT_TITLE: "Please input title",
  INPUT_CONTENT: "Please input content",
  PUSH_NOTIFICATION_FAILED: "Push notification failed",
}
